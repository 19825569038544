export const theme: { [key: string]: string } = {
  BLACK: '#000',
  BLUE: '#009CE5',
  BRIGHT_GREEN: '#00E556',
  DARK: '#303030',
  GD_BLUE: '#238BFF',
  GD_DARK_PURPLE: '#47004B',
  GD_DARK_RED: '#5A0606',
  GD_LIGHT_RED: '#FF2323',
  GD_PURPLE: '#7D05AA',
  RED: '#FF2121',
  SOFT_GRAY: '#C8D2DD',
  TEXT: '#FFF',
  WHITE: '#FFF',
};
